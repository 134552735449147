import classes from "../style/card.module.scss";
import MyIcon from "../../../components/IconsAntSvg";

// Card component. The component is used in the Dashboard page for to show the statistics of the reseller. Count and text are required.
// The component uses the MyIcon component to show the icon.

const Card = ({ count, text, src }) => {
    return (
        <div className={classes["card"]}>
            <div className={classes["card-description"]}>
                <p className={classes["card-text"]}>{text && text.toUpperCase()}</p>
            </div>

            <div className={classes["body"]}>
                <div className={classes["count"]}>{count}</div>

                <div className={classes["icon"]}>
                    <MyIcon children={src} />
                </div>
            </div>
        </div>
    );
};

export default Card;
