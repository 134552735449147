import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  SendOutlined,
  FundProjectionScreenOutlined,
  BarsOutlined,
  LinkOutlined,
  PicRightOutlined,
} from "@ant-design/icons";

import TEXTS from "../../config/texts";
import CONSTANTS from "../../config";

import "../styles/sidebar.scss";

import ICONS from "../../config/icons";

const Sidebar = ({ collapsed = null, setOpenMenuDrawer = null }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const profile = useSelector((state) => state.globalState.profile);
  const appInfo = useSelector((state) => state.globalState.appInfo);
  const translation = useSelector((state) => state.globalState.translation);

  return (
    <Layout.Sider
      className="sidebar"
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div
        className="sidebar-logo"
        onClick={() => {
          navigate("/dashboard");
        }}
      >
        <img src={appInfo?.logo} alt="" />
      </div>

      <Menu
        className="menu"
        defaultSelectedKeys={["/dashboard"]}
        selectedKeys={[location.pathname]}
        onClick={() => {
          if (setOpenMenuDrawer) {
            setOpenMenuDrawer(false);
          }
        }}
      >
        <Menu.Item
          key={"/dashboard"}
          className="ant-item"
          icon={<DashboardOutlined />}
        >
          <NavLink to="/dashboard">
            {translation["Dashboard"] || TEXTS["Dashboard"]}
          </NavLink>
        </Menu.Item>

        {profile.reseller?.is_reseller && (
          <Menu.Item
            key={"/dashboard/activated"}
            className="ant-item"
            icon={<FundProjectionScreenOutlined />}
          >
            <NavLink to="/dashboard/activated">
              {translation["Devices"] || TEXTS["Devices"]}
            </NavLink>
          </Menu.Item>
        )}

        {profile.reseller?.is_reseller && (
          <Menu.Item
            key={"/dashboard/activations"}
            className="ant-item"
            icon={<BarsOutlined />}
          >
            <NavLink to="/dashboard/activations">
              {translation["Activations history"] ||
                TEXTS["Activations history"]}
            </NavLink>
          </Menu.Item>
        )}

        {profile.reseller?.is_referral && (
          <Menu.Item
            key={"/dashboard/referral"}
            className="ant-item"
            icon={<LinkOutlined className="referral-icon" />}
          >
            <NavLink to="/dashboard/referral">
              {translation["Referral links"] || TEXTS["Referral links"]}
            </NavLink>
          </Menu.Item>
        )}

        {profile.reseller?.is_referral && (
          <Menu.Item
            key={"/dashboard/withdrawals"}
            className="ant-item"
            icon={<PicRightOutlined />}
          >
            <NavLink to="/dashboard/withdrawals">
              {translation["Withdrawals history"] ||
                TEXTS["Withdrawals history"]}
            </NavLink>
          </Menu.Item>
        )}
        <Menu.Item
          key={"/dashboard/resellers"}
          className="ant-item"
          icon={ICONS.SUBRESELLER}
        >
          <NavLink to="/dashboard/resellers">
            {translation["My resellers"] || TEXTS["My resellers"]}
          </NavLink>
        </Menu.Item>
      </Menu>

      <div className="sidebar-button-block">
        <div className="sidebar-button-descr">
          <p className={collapsed ? "close" : ""}>
            {translation["For support click button below"] ||
              TEXTS["For support click button below"]}
          </p>
        </div>
        <div
          className="sidebar-link-block"
          style={{ backgroundColor: "#3a3a3a", fontSize: "small" }}
        >
          <div>
            <SendOutlined className="sidebar-link-icon" />
          </div>
          <a
            className={collapsed ? "close" : ""}
            href={`mailto:${appInfo.email}`}
            target="_blank"
            style={{ fontSize: "small" }}
          >
            {appInfo?.email}
          </a>
        </div>
        <div className="res-ref-version">
          <p>Referral/Reseller {CONSTANTS.VERSION}</p>
        </div>
      </div>
    </Layout.Sider>
  );
};

export default Sidebar;
