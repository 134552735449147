import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import { ConfigProvider } from "antd";

import Layouts from "./layout";

import { REQUESTS } from "./api/requests";

import { actions } from "./store/index";

import LoginPage from "./pages/login";
import Withdrawals from "./pages/withdrawals";
import DashboardPage from "./pages/dashboard";
import Devices from "./pages/activatedDevice";
import PrivateRoute from "./pages/privateRoute";
import ReferralLinks from "./pages/referralLinks";
import ResetPasswordPage from "./pages/resetPassword";
import ActivationsHistory from "./pages/activationsHistory";
import PaymentErrorPage from "./pages/payment/PaymentErrorPage";
import PaymentSuccessPage from "./pages/payment/PaymentSuccessPage";
import CONSTANTS from "./config";
import Subresellers from "./pages/subresellers/Subresellers";

// From here managed routes, layouts, and pages. We have  9 pages in our app.
// We have a private route component that checks if the user is logged in or not. If the user is not logged in, it redirects to the login page.
// If the user is logged in, it renders the layout component. The layout component has a sidebar and a header.
// The sidebar has a menu that is rendered based on the user role. The pages are rendered based on the route. We have a dashboard page, a login page,
// a reset password page, a payment success page, a payment error page, a referral links page, an activated devices page, an activations history page,
// and a withdrawals page. User types are reseller, referral, or both. When user is reseller, it can see the activated devices page and the activations
// history page. When user is referral, he can see the referral links page and the withdrawals page. When user is both, he can see all pages.
// Payment success and payment error pages are rendered when the user makes a payment. Here we manage the app info and translations. Helmet is used for
// manage the title and favicon of the app. We have a global state that is managed by redux.

function App() {
    const dispatch = useDispatch();

    const { setAppInfo, setTranslation } = bindActionCreators(actions, dispatch);

    const profile = useSelector((state) => state.globalState.profile);
    const appInfo = useSelector((state) => state.globalState.appInfo);

    const [loading, setLoading] = useState(true);

    ConfigProvider.config({
        theme: {
            // primaryColor: `#0C6072`,
            primaryColor: CONSTANTS.APP_COLOR,
        },
    });

    const getTranslations = () => {
        function callback(data) {
            setTranslation(data);
        }

        REQUESTS.TRANSLATION({ id: 1 }, callback);
    };

    const getAppInfo = () => {
        setLoading(true);

        function callback(data) {
            setLoading(false);

            setAppInfo(data);
        }

        REQUESTS.APP_INFO(callback);
    };

    useEffect(() => {
        getAppInfo();
        getTranslations();

        const root = document.getElementById("root");

        root.style.setProperty("--app-color", CONSTANTS.APP_COLOR);
        root.style.setProperty("--active-color", CONSTANTS.ACTIVE_COLOR);
        root.style.setProperty("--link-color", CONSTANTS.LINK_COLOR);
        root.style.setProperty("--logo-size", CONSTANTS.LOGO_SIZE);
    }, []);

    return (
        <div className="App">
            <Helmet>
                <title>{`${
                    appInfo?.app_name ? `${appInfo.app_name} Reseller` : "Reseller"
                }`}</title>
                <link rel="icon" href={appInfo?.favicon} type="image/x-icon" />
            </Helmet>

            {loading && <div className="full-screen-loading"></div>}

            <Routes>
                <Route path={"/login"} element={<LoginPage />} />
                <Route path="/" element={<Navigate to={"/login"} replace />} />

                <Route path={"/reset_password"} element={<ResetPasswordPage />} />

                <Route
                    path={"/dashboard"}
                    element={
                        <PrivateRoute>
                            <Layouts />
                        </PrivateRoute>
                    }
                >
                    <Route index element={<DashboardPage />} />

                    <Route
                        path={"/dashboard/activated"}
                        element={profile.reseller?.is_reseller && <Devices />}
                    />

                    <Route
                        path={"/dashboard/activations"}
                        element={profile.reseller?.is_reseller && <ActivationsHistory />}
                    />

                    <Route
                        path={"/dashboard/referral"}
                        element={profile.reseller?.is_referral && <ReferralLinks />}
                    />

                    <Route
                        path={"/dashboard/withdrawals"}
                        element={profile.reseller?.is_referral && <Withdrawals />}
                    />

                    <Route path={"/dashboard/payment-success"} element={<PaymentSuccessPage />} />

                    <Route path={"/dashboard/payment-error"} element={<PaymentErrorPage />} />
                    <Route path={"/dashboard/resellers"} element={<Subresellers/>} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
