import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Input } from "antd";

import TEXTS from "../config/texts";
import { useEffect } from "react";
import { REQUESTS } from "../api/requests";

export default function ValidateMacAddress({ reset, onSubmit, placeholder }) {
  const translation = useSelector((state) => state.globalState.translation);

  const [value, setValue] = useState("");

  const [help, setHelp] = useState("");
  const [validateStatus, setValidateStatus] = useState("");

  const validateMacAddress = () => {
    setValidateStatus("");
    setHelp("");

    if (value.length === 0) {
      return;
    }
    setValidateStatus("validating");
    function callback() {
      setValidateStatus("success");
      setHelp(
        translation["Mac address is valid"] || TEXTS["Mac address is valid"]
      );
    }

    function errorCallback() {
      setValidateStatus("error");

      setHelp(
        translation["Mac address is not valid"] ||
          TEXTS["Mac address is not valid"]
      );
    }
    REQUESTS.ACTIVATED_DEVICE.MAC_VALIDATION(
      { mac: value },
      callback,
      errorCallback
    );
  };

  const resetInfo = () => {
    setHelp("");
    setValue("");
    setValidateStatus("");
  };

  const onInputEnter = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      validateMacAddress();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  useEffect(() => {
    resetInfo();
  }, [reset]);

  return (
    <Form.Item
      name="mac"
      hasFeedback={true}
      label={translation["Mac address"] || TEXTS["Mac address"]}
      help={value ? help : undefined}
      required
      rules={[
        {
          required: true,
          message:
            translation["Please input your mac!"] ||
            TEXTS["Please input your mac!"],
        },
      ]}
      validateStatus={validateStatus}
    >
      <Input
        onKeyDown={onInputEnter}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={placeholder}
      />
    </Form.Item>
  );
}
