import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Button, Input } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import TEXTS from "../../../config/texts";

import Message from "../../../components/message";
import classes from "../style/playListDrawer.module.scss";
import { REQUESTS } from "../../../api/requests";

export default function PlaylistDrawer({
  onClose,
  visible,
  current,
  deviceId,
  getPlaylist,
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const translation = useSelector((state) => state.globalState.translation);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
    const body = {
      name: values.name,
      url: values.url,
      deviceId,
    };
    function callback(response) {
      setLoading(false);
      onClose();
      getPlaylist();
    }
    function errorCallback(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }
    if (current) {
      body.id = current.id;
      REQUESTS.PLAYLIST.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.PLAYLIST.ADD(body, callback, errorCallback);
    }
  };

  const onSubmit = () => {
    setMessage({
      text: "",
      type: true,
    });
    form.submit();
  };

  const onInputEnter = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };
  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setMessage({
        text: "",
        type: true,
      });
    }
  }, [visible]);

  useEffect(() => {
    if (current) {
      form.setFields([
        { name: "name", value: current.name },
        { name: "url", value: current.url },
      ]);
    }
  }, [current]);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <Drawer
      width={windowWidth > 600 ? 600 : "75vw"}
      placement="right"
      title={
        current
          ? translation["Edit playlist"] || TEXTS["Edit playlist"]
          : translation["Add playlist"] || TEXTS["Add playlist"]
      }
      onClose={onClose}
      visible={visible}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout={"vertical"}
        name="add-playlist"
      >
        <Form.Item
          name="name"
          label={translation["Playlist name"] || TEXTS["Playlist name"]}
          rules={[
            {
              required: true,
              message:
                translation["Please input your playlist name!"] ||
                TEXTS["Please input your playlist name!"],
            },
          ]}
        >
          <Input onKeyDown={onInputEnter} />
        </Form.Item>
        <Form.Item
          name="url"
          label={translation["Playlist url"] || TEXTS["Playlist url"]}
          rules={[
            {
              required: true,
              message:
                translation["Please input your playlist url!"] ||
                TEXTS["Please input your playlist url!"],
            },
          ]}
        >
          <Input onKeyDown={onInputEnter} />
        </Form.Item>
        <Message message={message} />

        <Form.Item className={classes["current-device-button"]}>
          <Button
            type="primary"
            loading={loading}
            onClick={onSubmit}
            icon={<CheckOutlined />}
          >
            {translation["Save"] || TEXTS["Save"]}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
