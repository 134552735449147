import request from "./request";
import URLS from "./url";
import requestQuery from "./requestQuery";

export const REQUESTS = {
  // a
  APP_INFO: (callback, errorCallback) => {
    request("get", `${URLS.APP_INFO}`, {}, callback, errorCallback);
  },

  ACTIVATION_PACKAGES: (callback, errorCallback) => {
    request("get", `${URLS.ACTIVATION_PACKAGES}`, {}, callback, errorCallback);
  },

  ACTIVATED_DEVICE: {
    GET: (query, callback, errorCallback) => {
      request(
        "get",
        `${URLS.DEVICES + requestQuery(query)}`,
        {},
        callback,
        errorCallback
      );
    },

    ACTIVATE: (body, callback, errorCallback) => {
      request("post", `${URLS.ACTIVATE}`, body, callback, errorCallback);
    },

    GET_CODE: (query, callback, errorCallback) => {
      request(
        "get",
        `${URLS.ACTIVATION_CODE + requestQuery(query)}`,
        {},
        callback,
        errorCallback
      );
    },

    MAC_VALIDATION: (query, callback, errorCallback) => {
      request(
        "get",
        `${URLS.MAC_VALIDATION + requestQuery(query)}`,
        {},
        callback,
        errorCallback
      );
    },
    DELETE: (body, callback, errorCallback) => {
      request("delete", URLS.DEVICES, body, callback, errorCallback);
    },
  },

  ACTIVATIONS_HISTORY: {
    GET: (query, callback, errorCallback) => {
      request(
        "get",
        `${URLS.ACTIVATIONS_HISTORY + requestQuery(query)}`,
        {},
        callback,
        errorCallback
      );
    },
  },

  // b
  // c
  CAPTCHA_FOR_LOGIN:(callback) => {
    return request("get", `${URLS.CAPTCHA_FOR_LOGIN}`, {}, callback);
  },
  SENT_CAPTCHA_TOKEN: (body, callback, errorCallback) => {
    request("post", URLS.CAPTCHA_FOR_LOGIN, body, callback, errorCallback);
  },
  // d
  DEVICE: {
    PLAYLIST: {
      EDIT: (body, callback, errorCallback) => {
        request("put", `${URLS.PLAYLIST}`, body, callback, errorCallback);
      },
    },
  },
  DASHBOARD: {
    CHARTS: {
      RESELLER: (callback) => {
        request("get", `${URLS.RES_CHARTS}`, {}, callback);
      },

      REFERRAL: (callback, errorCallback) => {
        request("get", `${URLS.REF_CHARTS}`, {}, callback, errorCallback);
      },
    },

    CARDS: (callback) => {
      request("get", `${URLS.DASHBOARD}`, {}, callback);
    },
  },

  // e
  // f
  FORGOT_PASSWORD: (body, callback, errorCallback, networkError) => {
    request(
      "post",
      `${URLS.FORGOT_PASSWORD}`,
      body,
      callback,
      errorCallback,
      networkError
    );
  },

  // g

  // h
  // i
  // j
  // k
  // l
  LOGIN: (body, callback, errorCallback, networkError) => {
    request(
      "post",
      `${URLS.LOGIN}`,
      body,
      callback,
      errorCallback,
      networkError
    );
  },

  LANGUAGES: (callback, errorCallback) => {
    request("get", `${URLS.LANGUAGES}`, {}, callback, errorCallback);
  },

  // m
  // n
  // o
  // p
  PROFILE: {
    GET: (callback) => {
      request("get", `${URLS.PROFILE}`, {}, callback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", `${URLS.PROFILE}`, body, callback, errorCallback);
    },
  },
  PACKAGES: (callback) => {
    request("get", URLS.PACKAGES, {}, callback);
  },
  PLAYLIST: {
    GET: (query, callback, errorCallback) => {
      request(
        "get",
        `${URLS.PLAYLIST + requestQuery(query)}`,
        {},
        callback,
        errorCallback
      );
    },
    ADD: (body, callback, errorCallback) => {
      request("post", URLS.PLAYLIST, body, callback, errorCallback);
    },
    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.PLAYLIST, body, callback, errorCallback);
    },
    DELETE: (body, callback, errorCallback) => {
      request("delete", URLS.PLAYLIST, body, callback, errorCallback);
    },
  },

  PAYMENT_SETTINGS: (callback) => {
    request("get", URLS.PAYMENT_SETTINGS, {}, callback);
  },

  // q

  // r
  RESET_PASSWORD: (body, callback, errorCallback) => {
    request("post", `${URLS.RESET_PASSWORD}`, body, callback, errorCallback);
  },

  RESET_EMAIL: (body, callback, errorCallback) => {
    request("post", `${URLS.RESET_EMAIL}`, body, callback, errorCallback);
  },

  RESET_EMAIL_PUT: (body, callback, errorCallback) => {
    request("put", `${URLS.EMAIL}`, body, callback, errorCallback);
  },

  REFERRAL_LINKS: {
    GET: (query, callback, errorCallback) => {
      request(
        "get",
        `${URLS.REFERRAL_LINK + requestQuery(query)}`,
        {},
        callback,
        errorCallback
      );
    },

    ADD: (body, callback, errorCallback) => {
      request("post", `${URLS.REFERRAL_LINK}`, body, callback, errorCallback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", `${URLS.REFERRAL_LINK}`, body, callback, errorCallback);
    },

    DELETE: (id, callback, errorCallback) => {
      request(
        "delete",
        `${URLS.REFERRAL_LINK}`,
        { id },
        callback,
        errorCallback
      );
    },
  },

  // s
  SERVER_INFO: {
    GET: (callback, errorCallback) => {
      request("get", `${URLS.SERVER_INFO}`, {}, callback, errorCallback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", `${URLS.SERVER_INFO}`, body, callback, errorCallback);
    },
  },

  SUBRESELLERS: {
    GET: (query, callback) => {
      request(
        "get",
        `${URLS.SUBRESELLERS}${requestQuery(query)}`,
        {},
        callback
      );
    },
    EDIT: (body, callback, errorCallback) => {
      request("put", `${URLS.SUBRESELLERS}`, body, callback, errorCallback);
    },
    ADD: (body, callback, errorCallback) => {
      request("post", `${URLS.SUBRESELLERS}`, body, callback, errorCallback);
    },
  },

  SUBRESELLER_DISBALE: (body, callback, errorCallback) => {
    request(
      "put",
      `${URLS.SUBRESELLER_DISBALE}`,
      body,
      callback,
      errorCallback
    );
  },

  SUBRESELLER_ACTIVATION: {
    ADD: (body, callback, errorCallback) => {
      request(
        "post",
        `${URLS.SUBRESELLER_ACTIVATION}`,
        body,
        callback,
        errorCallback
      );
    },
    TAKE: (body, callback, errorCallback) => {
      request(
        "put",
        `${URLS.SUBRESELLER_ACTIVATION}`,
        body,
        callback,
        errorCallback
      );
    },
  },
  // t
  TRANSLATION: (query, callback, errorCallback) => {
    request(
      "get",
      `${URLS.TRANSLATION + requestQuery(query)}`,
      {},
      callback,
      errorCallback
    );
  },

  // u
  // v
  // w

  WITHDRAWALS: {
    GET: (query, callback, errorCallback) => {
      request(
        "get",
        `${URLS.WITHDRAWALS + requestQuery(query)}`,
        {},
        callback,
        errorCallback
      );
    },

    POST: (body, callback, errorCallback) => {
      request("post", `${URLS.WITHDRAWALS}`, body, callback, errorCallback);
    },
  },
  // x
  // y
  // z
};
