import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dropdown, Menu, Modal, Space } from "antd";
import {
    ThunderboltFilled,
    UserOutlined,
    LogoutOutlined,
    PicRightOutlined,
} from "@ant-design/icons";

import TEXTS from "../../config/texts";
import { confirm } from "../../config/confirm";

import ProfileDrawer from "./ProfileDrawer";
import WithdrawFundsDrawer from "./WithdrawFundsDrawer";
import BuyMoreActivationsDrawer from "./BuyMoreActivationsDrawer";

import classes from "../styles/profile.module.scss";

const ProfileBlock = ({ getProfile }) => {
    const navigate = useNavigate();

    // const appInfo = useSelector((state) => state.globalState.appInfo);

    const profile = useSelector((state) => state.globalState.profile);
    const translation = useSelector((state) => state.globalState.translation);

    const [visibleProfileDrawer, setVisibleProfileDrawer] = useState(false);
    const [visibleWithdrawFoundsDrawer, setVisibleWithdrawFoundsDrawer] = useState(false);
    const [visibleBuyMoreActivationsDrawer, setVisibleBuyMoreActivationsDrawer] = useState(false);

    const menu = (
        <Menu>
            {profile.reseller?.is_reseller && !profile.reseller?.parent_id && (
                <Menu.Item
                    className={classes["total"]}
                    icon={<ThunderboltFilled />}
                    onClick={() => setVisibleBuyMoreActivationsDrawer(true)}
                >
                    <span>
                        {translation["Buy more activations"] || TEXTS["Buy more activations"]}
                    </span>
                </Menu.Item>
            )}

            {profile.reseller?.is_referral && (
                <Menu.Item
                    className={classes["total"]}
                    icon={<PicRightOutlined />}
                    onClick={() => setVisibleWithdrawFoundsDrawer(true)}
                >
                    <span>{translation["Withdraw funds"] || TEXTS["Withdraw funds"]}</span>
                </Menu.Item>
            )}

            <Menu.Item
                className={classes["total"]}
                icon={<UserOutlined />}
                onClick={() => setVisibleProfileDrawer(true)}
            >
                <span>{translation["Edit profile"] || TEXTS["Edit profile"]}</span>
            </Menu.Item>

            <Menu.Item
                className={classes["total-3"]}
                icon={<LogoutOutlined />}
                onClick={() =>
                    confirm(
                        translation["Do you want to logout ?"] || TEXTS["Do you want to logout ?"],
                        () => {
                            localStorage.clear();
                            navigate("/login");
                        },
                        translation["Logout"] || TEXTS["Logout"],
                        translation["Cancel"] || TEXTS["Cancel"]
                    )
                }
            >
                <span>{translation["Logout"] || TEXTS["Logout"]}</span>
            </Menu.Item>
        </Menu>
    );

    return (
        <Space direction="vertical">
            <Space>
                <Dropdown overlay={menu} placement="bottom" trigger="click">
                    <div className={classes["profile-block"]}>
                        <img src="/assets/images/icons/user.png" alt="img" />

                        {profile && profile.reseller && (
                            <>
                                <span style={{ marginRight: 4 }}>{profile.reseller.name}</span>
                                <span>{profile.reseller.surname}</span>
                            </>
                        )}
                    </div>
                </Dropdown>

                <ProfileDrawer
                    getProfile={getProfile}
                    visible={visibleProfileDrawer}
                    onClose={() => setVisibleProfileDrawer(false)}
                />

                <BuyMoreActivationsDrawer
                    // getProfile={getProfile}
                    visible={visibleBuyMoreActivationsDrawer}
                    onClose={() => setVisibleBuyMoreActivationsDrawer(false)}
                />

                <WithdrawFundsDrawer
                    visible={visibleWithdrawFoundsDrawer}
                    onClose={() => setVisibleWithdrawFoundsDrawer(false)}
                    
                />
            </Space>
        </Space>
    );
};

export default ProfileBlock;
