import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { Button, Drawer, Form, Input, InputNumber, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { actions } from "../../store/index";

import { REQUESTS } from "../../api/requests";

import TEXTS from "../../config/texts";
import country from "../../country.json";

import Message from "../../components/message";

import classes from "../styles/withdrawFundsDrawer.module.scss";

const { Option } = Select;

const WithdrawFundsDrawer = ({ visible, onClose, removeSearchSorter }) => {
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { onGetWithdrawAgain, setProfile } = bindActionCreators(
    actions,
    dispatch
  );

  const translation = useSelector((state) => state.globalState.translation);

  const [loading, setLoading] = useState(false);

  const [paymentMethodWithdraw, setPaymentMethodWithdraw] = useState("paypal");

  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const [form] = Form.useForm();

  const getProfile = () => {
    function callback(data) {
      setProfile(data);
    }

    REQUESTS.PROFILE.GET(callback);
  };

  const onFinish = (values) => {
    setLoading(true);

    const body1 = {
      email: values.email,
      amount: values.price,
      method: paymentMethodWithdraw,
    };

    const body2 = {
      name: values.name,
      surname: values.surname,
      amount: values.price,
      country: values.country,
      method: paymentMethodWithdraw,
      email: values.wired_email,
      swift: values.swift,
      iban: values.iban,
    };

    function callback() {
      setLoading(false);
      onClose();
      onGetWithdrawAgain(true);
      if (removeSearchSorter) removeSearchSorter();
      getProfile();
    }

    function errorCallback(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    REQUESTS.WITHDRAWALS.POST(
      paymentMethodWithdraw === "paypal" ? body1 : body2,
      callback,
      errorCallback
    );
  };

  const handleChange = (value) => {
    setPaymentMethodWithdraw(value);
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onValuesChange = () => {
    removeMessage();
  };

  const onWithdraw = () => {
    removeMessage();
    form.submit();
  };

  const onInputEnter = (event) => {
    if (event.key === "Enter") {
      onWithdraw();
    }
  };

  useEffect(() => {
    handleChange("paypal");
  }, [visible]);

  useEffect(() => {
    removeMessage();
    if (!visible) {
      onGetWithdrawAgain(false);
      removeMessage();
      setLoading(false);
      setPaymentMethodWithdraw(null);
    } else {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <>
      <Drawer
        width={windowWidth > 600 ? 600 : "75vw"}
        title={translation["Withdraw funds"] || TEXTS["Withdraw funds"]}
        className={classes["withdraw-drawer"]}
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <Form
          form={form}
          layout={"vertical"}
          name="withdraw funds"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          initialValues={{
            method: "paypal",
          }}
        >
          <div className={classes["withdraw-form"]}>
            <Form.Item
              name="price"
              label={translation["Price"] || TEXTS["Price"]}
              rules={[
                {
                  required: true,
                  message:
                    translation["Please input your price!"] ||
                    TEXTS["Please input your price!"],
                },
              ]}
            >
              <input
                type="number"
                className="number-inp"
                onKeyDown={onInputEnter}
                placeholder={translation["Enter price"] || TEXTS["Enter price"]}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>

            <Form.Item
              className={classes["item"]}
              name="method"
              label={
                translation["Choose payment method"] ||
                TEXTS["Choose payment method"]
              }
            >
              <Select
                placeholder={
                  translation["Choose payment method"] ||
                  TEXTS["Choose payment method"]
                }
                className={classes["select"]}
                onChange={handleChange}
              >
                <Option value="paypal">Paypal</Option>
                <Option value="wired transfer">
                  {translation["Wired transfer"] || TEXTS["Wired transfer"]}
                </Option>
              </Select>
            </Form.Item>

            {paymentMethodWithdraw === "paypal" ? (
              <div className={classes["paypal-open"]}>
                <Form.Item
                  label={
                    translation["PayPal cashout email"] ||
                    TEXTS["PayPal cashout email"]
                  }
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message:
                        translation[
                          "Please input your paypal cashout email!"
                        ] || TEXTS["Please input your paypal cashout email!"],
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    onKeyDown={onInputEnter}
                    placeholder={
                      translation["Enter your paypal cashout email"] ||
                      TEXTS["Enter your paypal cashout email"]
                    }
                  />
                </Form.Item>

                <Form.Item
                  className={classes["email-input"]}
                  label={
                    translation["Confirm paypal cashout email"] ||
                    TEXTS["Confirm paypal cashout email"]
                  }
                  name="confirm_email"
                  hasFeedback
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message:
                        translation[
                          "Please confirm your paypal cashout email!"
                        ] || TEXTS["Please confirm your paypal cashout email!"],
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("email") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            "The two email that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    onKeyDown={onInputEnter}
                    placeholder={
                      translation["Confirm your paypal cashout email"] ||
                      TEXTS["Confirm your paypal cashout email"]
                    }
                  />
                </Form.Item>
              </div>
            ) : (
              <div className={classes["wired-open"]}>
                <div className={classes["withdraw-transfer"]}>
                  <Form.Item
                    label={translation["Name"] || TEXTS["Name"]}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message:
                          translation["Please input your name!"] ||
                          TEXTS["Please input your name!"],
                      },
                    ]}
                  >
                    <Input
                      onKeyDown={onInputEnter}
                      placeholder={
                        translation["Enter your name"] ||
                        TEXTS["Enter your name"]
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label={translation["Surname"] || TEXTS["Surname"]}
                    name="surname"
                    rules={[
                      {
                        required: true,
                        message:
                          translation["Please input your surname!"] ||
                          TEXTS["Please input your surname!"],
                      },
                    ]}
                  >
                    <Input
                      onKeyDown={onInputEnter}
                      placeholder={
                        translation["Enter your surname"] ||
                        TEXTS["Enter your surname"]
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label={translation["Email"] || TEXTS["Email"]}
                    name="wired_email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message:
                          translation["Please input your email!"] ||
                          TEXTS["Please input your email!"],
                      },
                    ]}
                  >
                    <Input
                      onKeyDown={onInputEnter}
                      placeholder={
                        translation["Enter your email"] ||
                        TEXTS["Enter your email"]
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label={
                      translation["Swift/Bic code"] || TEXTS["Swift/Bic code"]
                    }
                    name="swift"
                    rules={[
                      {
                        required: true,
                        message:
                          translation["Please input your Swift/Bic code!"] ||
                          TEXTS["Please input your Swift/Bic code!"],
                      },
                    ]}
                  >
                    <Input
                      onKeyDown={onInputEnter}
                      placeholder={
                        translation["Enter your swift/bic code"] ||
                        TEXTS["Enter your swift/bic code"]
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    className={classes["item"]}
                    name="country"
                    label={translation["Country"] || TEXTS["Country"]}
                    rules={[
                      {
                        required: true,
                        message:
                          translation["Please choose your country"] ||
                          TEXTS["Please choose your country"],
                      },
                    ]}
                  >
                    <Select
                      className={classes["select"]}
                      showSearch={true}
                      placeholder={
                        translation["Select your country"] ||
                        TEXTS["Select your country"]
                      }
                    >
                      {country.map((item) => {
                        return (
                          <Option key={item.code} value={`${item.name}`}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={
                      translation["Iban account number"] ||
                      TEXTS["Iban account number"]
                    }
                    name="iban"
                    rules={[
                      {
                        required: true,
                        message:
                          translation[
                            "Please input your iban account number!"
                          ] || TEXTS["Please input your iban account number!"],
                      },
                    ]}
                  >
                    <Input
                      onKeyDown={onInputEnter}
                      placeholder={
                        translation["Enter your Iban account number"] ||
                        TEXTS["Enter your Iban account number"]
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            )}

            {/*<Message message={message} />*/}

            {/*<Form.Item className={classes["withdraw-button"]}>*/}
            {/*    <Button*/}
            {/*        loading={loading}*/}
            {/*        type="primary"*/}
            {/*        onClick={onWithdraw}*/}
            {/*        icon={<CheckOutlined />}*/}
            {/*    >*/}
            {/*        {translation["Save"] || TEXTS["Save"]}*/}
            {/*    </Button>*/}
            {/*</Form.Item>*/}
          </div>

          <Message message={message} />

          <Form.Item className={classes["withdraw-button"]}>
            <Button
              loading={loading}
              type="primary"
              onClick={onWithdraw}
              icon={<CheckOutlined />}
            >
              {translation["Save"] || TEXTS["Save"]}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default WithdrawFundsDrawer;
