const CONSTANTS = {
    NOT_AVAILABLE: "N/A",

    JWT: process.env.REACT_APP_JWT || "JWT",

    API_HOST: process.env.REACT_APP_API_URL || "http://api.jaroiptv.com/",

    REACT_APP_WEB_DOMAIN: process.env.REACT_APP_WEB_DOMAIN || "http://jaroiptv.com/",

    APP_NAME: process.env.REACT_APP_APPLICATION_NAME || "Zoro  PLAYER WEB",

    LINK_TO: "mailto:" + process.env.REACT_APP_SUPPORT_EMAIL || "zorojorocom@gmail.com",

    PAYPAL_SUCCESS: process.env.REACT_APP_API_PAYPAL_SUCCESS_MESSAGE || "You'r payment was completed with success",

    APP_COLOR: process.env.REACT_APP_COLOR || "black",

    // ACTIVE_COLOR: process.env.REACT_APP_ACTIVE_COLOR || "green",
    LOGO_SIZE: process.env.REACT_APP_LOGO_SIZE || "70px",
    ACTIVE_COLOR: process.env.REACT_APP_ACTIVE_COLOR || "black",
    LINK_COLOR: process.env.REACT_APP_LINK_COLOR || "white",
    VERSION:"v1.0.0",
};

export default CONSTANTS;
