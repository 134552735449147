import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Card from "./Card";
import Chart from "./Chart";

import { REQUESTS } from "../../../api/requests";

import TEXTS from "../../../config/texts";
import ICONS from "../../../config/icons";

import classes from "../style/resellerStatistics.module.scss";

// Reseller statistics component. The component is used in the Dashboard page for to show the statistics of the reseller. Get charts and cards data from the
// server. The component uses the REQUESTS.DASHBOARD.CARDS function to get the cards data. The component uses the REQUESTS.DASHBOARD.CHARTS.RESELLER function
// to get the charts data. The component uses the Card component to show the cards data. The component uses the Chart component to show the charts data.
// Translation is used to get the text from the translation file.

const ResellerStatistics = () => {
    const translation = useSelector((state) => state.globalState.translation);

    // The activatedCards state is used to get the activated cards data from the server.
    const [activatedCards, setActivatedCards] = useState({
        activation_count: 0,
        activations_this_month: 0,
        activations_today: 0,
    });

    // The activationCards state is used to get the activation cards data from the server.
    const [activationCards, setActivationCards] = useState({
        spend_month: 0,
        spend_today: 0,
        spend_total: 0,
    });

    // The activatedChart state is used to get the activated chart data from the server.
    const [activatedChart, setActivatedChart] = useState([]);

    // The activationChart state is used to get the activation chart data from the server.
    const [activationChart, setActivationChart] = useState([]);

    const getCards = () => {
        function callback(data) {
            if (data.reseller) {
                setActivatedCards(data.reseller.activated); 
                setActivationCards(data.reseller.activation);
            }
        }

        REQUESTS.DASHBOARD.CARDS(callback);
    };

    const getCharts = () => {
        function callback(data) {
            setActivatedChart(data?.activated_devices_data);
            setActivationChart(data?.activations_data);
        }

        REQUESTS.DASHBOARD.CHARTS.RESELLER(callback);
    };

    useEffect(() => {
        getCards();
        getCharts();
    }, []);

    return (
        <div className={classes["container"]}>
            <div className={classes["title"]}>
                <span>{translation["Reseller"] || TEXTS["Reseller"]}</span>
            </div>

            <div className={classes["sub-container"]}>
                <div className={classes["dashboard-statistics-block"]}>
                    <div className={classes["dashboard-statistics-block-title"]}>
                        <div className={classes["dashboard-statistics-block-text"]}>
                            <span>
                                {translation["Activation history"] || TEXTS["Activation history"]}
                            </span>
                        </div>
                    </div>
                    <div className={classes["card-block"]}>
                        <Card
                            count={activatedCards.activation_count}
                            text={translation["Activation count"] || TEXTS["Activation count"]}
                            src={ICONS.ACTIVATION_COUNT}
                        />

                        <Card
                            count={activatedCards.activations_this_month}
                            text={translation["Activations month"] || TEXTS["Activations month"]}
                            src={ICONS.ACTIVATIONS_MONTH}
                        />

                        <Card
                            count={activatedCards.activations_today}
                            text={translation["Activations today"] || TEXTS["Activations today"]}
                            src={ICONS.ACTIVATIONS_TODAY}
                        />
                    </div>

                    <div className={classes["grafic"]}>
                        <Chart charts={activatedChart} />
                    </div>
                </div>

                <div className={classes["dashboard-statistics-block"]}>
                    <div className={classes["dashboard-statistics-block-title"]}>
                        <div className={classes["dashboard-statistics-block-text"]}>
                            <span>
                                {translation["Activation buy history"] ||
                                    TEXTS["Activation buy history"]}
                            </span>
                        </div>
                    </div>
                    <div className={classes["card-block"]}>
                        <Card
                            count={activationCards.spend_month}
                            text={translation["Spend month"] || TEXTS["Spend month"]}
                            src={ICONS.ACTIVATION_COUNT}
                        />

                        <Card
                            count={activationCards.spend_today}
                            text={translation["Spend today"] || TEXTS["Spend today"]}
                            src={ICONS.ACTIVATIONS_MONTH}
                        />

                        <Card
                            count={activationCards.spend_total}
                            text={translation["Spend total"] || TEXTS["Spend total"]}
                            src={ICONS.ACTIVATIONS_TODAY}
                        />
                    </div>

                    <div className={classes["grafic"]}>
                        <Chart charts={activationChart} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResellerStatistics;
