import { useSelector } from "react-redux";

import TEXTS from "../../config/texts";

import Pages from "../../components/Pages";
import ResellerStatistics from "./components/ResellerStatistics";
import ReferralStatistics from "./components/ReferralStatistics";

import classes from "./index.module.scss";

// Dashboard page for showing statistics of reseller and referral. We are using useSelector to get the profile and translation from the global state.
// Profile is used to check if the user is reseller or referral. When the user is reseller he can see the reseller statistics and when the user is referral
// he can see the referral statistics. Translation is used to get the translation of the page title.

export default function DashboardPage() {
    const profile = useSelector((state) => state.globalState.profile);
    const translation = useSelector((state) => state.globalState.translation);

    return (
        <Pages title={translation["Dashboard"] || TEXTS["Dashboard"]}>
            <div className={classes["dashboard-statistics-block"]}>
                {profile.reseller?.is_reseller && <ResellerStatistics />}

                {profile.reseller?.is_referral && <ReferralStatistics />}
            </div>
        </Pages>
    );
}
