import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Card from "./Card";
import Chart from "./Chart";

import { REQUESTS } from "../../../api/requests";

import TEXTS from "../../../config/texts";
import ICONS from "../../../config/icons";

import classes from "../style/referralStatistics.module.scss";

// Referral statistics component. The component is used in the Dashboard page for to show the statistics of the referral. Get charts and cards data from the
// server.  The component uses the REQUESTS.DASHBOARD.CARDS function to get the cards data. The component uses the REQUESTS.DASHBOARD.CHARTS.REFERRAL function
// to get the charts data. The component uses the Card component to show the cards data. The component uses the Chart component to show the charts data.
// Translation is used to get the text from the translation file.

const ReferralStatistics = () => {
    const translation = useSelector((state) => state.globalState.translation);

    // The linksCards state is used to get the links cards data from the server.
    const [linksCards, setLinksCards] = useState({
        total_activated: 0,
        total_clicked: 0,
        total_links: 0,
    });

    // The withdrawHistoryCards state is used to get the withdraw history cards data from the server.
    const [withdrawHistoryCards, setWithdrawHistoryCards] = useState({
        earnings_this_month: 0,
        total_earnings: 0,
        total_withdraw: 0,
    });

    // The linksChart state is used to get the links chart data from the server.
    const [linksChart, setLinksChart] = useState([]);

    // The withdrawsHistoryChart state is used to get the withdraws history chart data from the server.
    const [withdrawsHistoryChart, setWithdrawsHistoryChart] = useState([]);

    const getCards = () => {
        function callback(data) {
            if (data.referral) {
                setLinksCards(data.referral.links);
                setWithdrawHistoryCards(data.referral.withdrawHistory);
            }
        }

        REQUESTS.DASHBOARD.CARDS(callback);
    };

    const getCharts = () => {
        function callback(data) {
            setLinksChart(data.links_data);
            setWithdrawsHistoryChart(data.withdraws_data);
        }

        REQUESTS.DASHBOARD.CHARTS.REFERRAL(callback);
    };

    useEffect(() => {
        getCards();
        getCharts();
    }, []);

    return (
        <div className={classes["ref-container"]}>
            <div className={classes["title"]}>
                <span>{translation["Referral"] || TEXTS["Referral"]}</span>
            </div>
            <div className={classes["ref-sub-container"]}>
                <div className={classes["ref-dashboard-statistics-block"]}>
                    <div className={classes["ref-dashboard-statistics-block-title"]}>
                        <div className={classes["ref-dashboard-statistics-block-text"]}>
                            <span>{translation["Links"] || TEXTS["Links"]}</span>
                        </div>
                    </div>
                    <div className={classes["ref-card-block"]}>
                        <Card
                            count={linksCards.total_activated}
                            text={translation["Total activated"] || TEXTS["Total activated"]}
                            src={ICONS.TOTAL_ACTIVATED}
                        />

                        <Card
                            count={linksCards.total_clicked}
                            text={translation["Total clicked"] || TEXTS["Total clicked"]}
                            src={ICONS.TOTAL_CLICKED}
                        />

                        <Card
                            count={linksCards.total_links}
                            text={translation["Total links"] || TEXTS["Total links"]}
                            src={ICONS.TOTAL_WITHDRAW}
                        />
                    </div>

                    <div className={classes["grafic"]}>
                        <Chart charts={linksChart} />
                    </div>
                </div>

                <div className={classes["ref-dashboard-statistics-block"]}>
                    <div className={classes["ref-dashboard-statistics-block-title"]}>
                        <div className={classes["ref-dashboard-statistics-block-text"]}>
                            <span>
                                {translation["Withdraw history"] || TEXTS["Withdraw history"]}
                            </span>
                        </div>
                    </div>
                    <div className={classes["ref-card-block"]}>
                        <Card
                            count={withdrawHistoryCards.earnings_this_month}
                            text={
                                translation["Earnings this month"] || TEXTS["Earnings this month"]
                            }
                            src={ICONS.TOTAL_ACTIVATED}
                        />

                        <Card
                            count={withdrawHistoryCards.total_earnings}
                            text={translation["Total earnings"] || TEXTS["Total earnings"]}
                            src={ICONS.TOTAL_CLICKED}
                        />

                        <Card
                            count={withdrawHistoryCards.total_withdraw}
                            text={translation["Total withdraw"] || TEXTS["Total withdraw"]}
                            src={ICONS.TOTAL_WITHDRAW}
                        />
                    </div>

                    <div className={classes["grafic"]}>
                        <Chart charts={withdrawsHistoryChart} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReferralStatistics;
