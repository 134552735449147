import axios from "axios";
import CONSTANTS from "../config";

const request = (method, url, body, callback, errorCallback, networkError) => {
    let myAxios = axios.create();

    myAxios.defaults.headers.common["Authorization"] = localStorage.getItem(`${CONSTANTS.JWT}`);


    myAxios[method](url, method === "delete" ? { data: body } : body)
        .then((response) => {
            if (!response.data.error) {
                callback(response.data.message);
            } else {
                errorCallback(response.data.message);
            }
        })
        .catch((error) => {
            if (networkError) {
                networkError(error.message);
            }
            if (error.response && error.response.data && error.response.data.message) {
                errorCallback(error.response.data.message);
            }
        });
};

export default request;
