import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Drawer, Form } from "antd";

import TEXTS from "../../../config/texts";

import { REQUESTS } from "../../../api/requests";

import GetCodeDrawer from "./GetCodeDrawer";

import Message from "../../../components/message";
import ValidateMacAddress from "../../../components/ValidateMacAddress";

import classes from "../style/addExistingDeviceDrawer.module.scss";

const AddExistingDeviceDrawer = ({
  visible,
  onClose,
  getDevices,
  removeSearchSorter,
}) => {
  const translation = useSelector((state) => state.globalState.translation);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [visibleGetCodeDrawer, setVisibleGetCodeDrawer] = useState(false);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);

    function callback() {
      setLoading(false);
      setVisibleGetCodeDrawer(true);
    }

    function errorCallback(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    REQUESTS.ACTIVATED_DEVICE.GET_CODE(values, callback, errorCallback);
  };

  const onValuesChange = (change, all) => {
    removeMessage();
  };

  const removeMessage = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  const onSubmit = () => {
    removeMessage();
    form.submit();
  };

  useEffect(() => {
    if (!visible) {
      setLoading(false);
      removeMessage();
    } else {
      form.resetFields();
    }
  }, [visible]);

  const closeGetCodeDrawer = () => {
    onClose();
    setVisibleGetCodeDrawer(false);
    getDevices();
  };
  
  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);
  return (
    <>
      <Drawer
        width={windowWidth > 600 ? 600 : "75vw"}
        title={translation["Existing device"] || TEXTS["Existing device"]}
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <Form
          form={form}
          layout={"vertical"}
          name="add-existing-device"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          initialValues={{
            remember: true,
          }}
        >
          <ValidateMacAddress
            reset={visible}
            onSubmit={onSubmit}
            placeholder={
              translation["Enter device mac address"] ||
              TEXTS["Enter device mac address"]
            }
          />

          <Message message={message} />
          <Form.Item className={classes["form-get-code-button"]}>
            <Button type="primary" loading={loading} onClick={onSubmit}>
              {translation["Get code"] || TEXTS["Get code"]}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>

      <GetCodeDrawer
        visible={visibleGetCodeDrawer}
        onClose={() => setVisibleGetCodeDrawer(false)}
        mac={form.getFieldValue("mac")}
        closeGetCodeDrawer={closeGetCodeDrawer}
        removeSearch={removeSearchSorter}
      />
    </>
  );
};

export default AddExistingDeviceDrawer;
