import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Form, Input, Checkbox, Drawer, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import TEXTS from "../../../config/texts";
import { REQUESTS } from "../../../api/requests";
import country from "../../../country.json";
import Message from "../../../components/message";

export default function SubresellerDrawer({
  visible,
  onClose,
  current = null,
  getSubresellers,
}) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const translation = useSelector((state) => state.globalState.translation);

  const [form] = Form.useForm();

  function onFinish(values) {
    setLoading(true);

    const body = {
      name: values.name,
      surname: values.surname,
      email: values.email,
      phone: values.phone,
      company: values.company,
      address: values.address,
      country: values.country,
    };

    if (values.password.length) {
      body.password = values.password;
    }

    if (current) {
      body["id"] = current.id;

      REQUESTS.SUBRESELLERS.EDIT(
        body,
        (data) => {
          setLoading(false);
          getSubresellers();
          onClose();
        },
        (err) => {
          setLoading(false);
          setMessage({
            text: err,
            type: false,
          });
        }
      );
    } else {
      REQUESTS.SUBRESELLERS.ADD(
        body,
        (data) => {
          setLoading(false);
          getSubresellers();
          onClose();
        },
        (err) => {
          setLoading(false);
          setMessage({
            text: err,
            type: false,
          });
        }
      );
    }
  }

  const onSubmit = () => {
    form.submit();
    setMessage({
      text: "",
      type: true,
    });
  };

  const onValuesChange = () => {
    setMessage({
      text: "",
      type: true,
    });
  };

  useEffect(() => {
    if (current) {
      form.setFields([
        {
          name: "name",
          value: current.name,
        },

        {
          name: "surname",
          value: current.surname,
        },

        {
          name: "company",
          value: current.company,
        },

        {
          name: "country",
          value: current.country,
        },

        {
          name: "phone",
          value: current.phone,
        },

        {
          name: "email",
          value: current.email,
        },

        {
          name: "address",
          value: current.address,
        },

        {
          name: "password",
          value: "",
        },
      ]);
    } else {
      form.resetFields();
    }
  }, [current, visible]);

  useEffect(() => {
    if (!visible) {
      setMessage({
        text: "",
        type: true,
      });
    }
  }, [visible]);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <div>
      <Drawer
        width={windowWidth > 600 ? 600 : "75vw"}
        title={
          current
            ? translation["Edit Reseller"] || TEXTS["Edit Reseller"]
            : translation["Add Reseller"] || TEXTS["Add Reseller"]
        }
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <Form
          form={form}
          name="profile"
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          <div className="subreseller-drawer-content">
            <Form.Item
              label={translation["Name"] || TEXTS["Name"]}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="surname"
              label={translation["Surname"] || TEXTS["Surname"]}
              rules={[
                {
                  required: true,
                  message: "Please input your surname!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone"
              label={translation["Phone"] || TEXTS["Phone"]}
              rules={[
                {
                  required: true,
                  message: "Please input your Phone!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label={translation["Email"] || TEXTS["Email"]}
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="company"
              label={translation["Company"] || TEXTS["Company"]}
              rules={[
                {
                  required: true,
                  message: "Please input your Company!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="address"
              label={translation["Address"] || TEXTS["Address"]}
              rules={[
                {
                  required: true,
                  message: "Please input your address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="country"
              label={translation["Country"] || TEXTS["Country"]}
              rules={[
                {
                  required: true,
                  message: "Please input your country!",
                },
              ]}
            >
              <Select showSearch={true}>
                {country.map((item) => {
                  return (
                    <Select.Option key={item.code} value={`${item.name}`}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="password"
              label={translation["Password"] || TEXTS["Password"]}
            >
              <Input.Password />
            </Form.Item>
          </div>{" "}
          <Message message={message} />
          <Form.Item>
            <div className="subreseller-drawer-content_save-btn-container">
              <Button
                type="primary"
                onClick={onSubmit}
                loading={loading}
                className="subreseller-drawer-content_save-btn-container_btn"
              >
                {translation["Save"] || TEXTS["Save"]}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}
