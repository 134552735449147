import { Column } from "@ant-design/plots";

// Chart component is used to show the chart in the dashboard page. The component uses the Ant Design Plots library to show the chart.

export default function Chart({ charts }) {
    const data = [];

    for (let i = 0; i < charts?.labels?.length; i++) {
        data.push({
            type: charts?.labels[i],
            value: charts?.data[i],
        });
    }

    const config = {
        data,
        columnWidthRatio: 0.2,
        xField: "type",
        yField: "value",
        seriesField: "",
        color: ({ type }) => {
            if (type === "10-30分" || type === "30+分") {
                return "#F4664A";
            }

            return "#4cf661";
        },
        label: {
            content: (originData) => {
                const val = parseFloat(originData.value);

                if (val < 0.05) {
                    return (val * 100).toFixed(1) + "%";
                }
            },
            offset: 10,
        },
        legend: false,
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
    };

    return <Column {...config} />;
}
